import {getCurrentInstance, watch, ref, onMounted, onUnmounted} from "@vue/composition-api"

export function useRoute() {
  const vm = getCurrentInstance();
  let proxy = vm.proxy;
  let timeId = ref(null);
  let $route = ref(null);

  onMounted(() => {
    if (timeId.value != null) {
      clearInterval(timeId.value);
      timeId.value = null
    }
    timeId.value = setInterval(() => {
      $route.value = proxy.$route;
    });
  })
  onUnmounted(() => {
    if (timeId.value != null) {
      clearInterval(timeId.value);
      timeId.value = null
    }
  })

  return $route
}
